import { render, staticRenderFns } from "./NewPlans.vue?vue&type=template&id=5f93c43f&scoped=true"
import script from "./NewPlans.vue?vue&type=script&lang=js"
export * from "./NewPlans.vue?vue&type=script&lang=js"
import style0 from "./NewPlans.vue?vue&type=style&index=0&id=5f93c43f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f93c43f",
  null
  
)

export default component.exports