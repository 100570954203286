<template>
  <div>
    <div v-if="siteUrl === 'true'">
      <QuestionCall :phone="turnkeySolutionsInfo.phoneNumber ? turnkeySolutionsInfo.phoneNumber : phone" />
    </div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-xxl-6 col-xl-6 col-lg-7 col-md-8 col-12 mb-4">
            <div class="foot-logo mb-3">
              <img :src="footerIcon" alt="" />
            </div>
            <p v-if="siteUrl === 'false'">
              Access to the insurance plans and large group pricing on this
              website is predicted upon joining Elevate Wellness, a national
              association with over 100,000 members
            </p>
            <ul v-if="!continueEnrollmentFlag">
              <li>
                Phone: <a href="javascript:void(0)">{{ turnkeySolutionsInfo.phoneNumber ? turnkeySolutionsInfo.phoneNumber : phone }}</a>
              </li>
              <li>
                Email: <a href="javascript:void(0)">{{ turnkeySolutionsInfo.email ? turnkeySolutionsInfo.email : email }}</a>
              </li>
            </ul>
            <div v-if="siteUrl === 'true'">
              <div class="link-container">
                <b-row class="mt-3">
                  <b-col cols="12" sm="4" md="4" xl="4" lg="4" class="mb-3">
                    <div>BROKERS/REPS</div>
                    <div>
                      <a :href="menuLink.routeToRepRegistration" target="_blank"
                        ><span>- REP REGISTRATION</span></a
                      >
                    </div>
                    <div>
                      <a :href="menuLink.routeToRepDashboard" target="_blank"
                        ><span>- REP DASHBOARD</span></a
                      >
                    </div>
                    <div>
                      <span v-b-modal.accessCode-pop @click="resetRepEnroll()"
                        >- Access Code</span
                      >
                    </div>
                    <div>
                      <span @click="routeToNotMySITE">- NOT MY SITE</span>
                    </div>
                  </b-col>
                  <b-col cols="12" sm="4" md="4" xl="4" lg="4" class="mb-3">
                    <div>EMPLOYEE/MEMBERS</div>
                    <div>
                      <span
                        v-b-modal.employeeEnrollment-pop
                        @click="resetEnrollGroup()"
                        >- EMPLOYEE ENROLL</span
                      >
                    </div>
                    <div>
                      <a
                        :href="menuLink.routeToMemberRegistration"
                        target="_blank"
                        ><span>- MEMBER LOGIN</span></a
                      >
                    </div>
                    <div>
                      <a :href="menuLink.routeToMemberDashboard" target="_blank"
                        ><span>- MEMBER DASHBOARD</span></a
                      >
                    </div>
                    <div>
                      <span
                        v-b-modal.howToEnrollVideo
                        @click="getVideoLink('howToEnrollVideo')"
                        >- HOW TO ENROLL VIDEO</span
                      >
                    </div>
                  </b-col>
                  <b-col cols="12" sm="4" md="4" xl="4" lg="4" class="mb-3">
                    <div>EMPLOYER/GROUPS</div>
                    <div>
                      <a
                        :href="menuLink.routeToGroupRegistration"
                        target="_blank"
                        ><span>- GROUP APPLICATION</span></a
                      >
                    </div>
                    <div>
                      <a :href="menuLink.routeToGroupDashboard" target="_blank"
                        ><span>- GROUP LOGIN</span></a
                      >
                    </div>
                    <div>
                      <span
                        v-b-modal.howToEnrollVideo
                        @click="getVideoLink('howToVideo')"
                        >- HOW TO VIDEO</span
                      >
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="col-xxl-6 col-xl-6 col-lg-5 col-md-4 col-12 mb-4">
            <p>Download The Member App</p>
            <div class="app-icon">
              <a v-bind:href="iosLink" target="_blank"
                ><img :src="iosImgLink" alt="iOS App" class="img-fluid"
              /></a>
              <a
                v-bind:href="androidLink"
                v-if="googlePlay == true"
                target="_blank"
                ><img :src="androidImgLink" alt="Android App" class="img-fluid"
              /></a>
            </div>
            <p>Click the Appropriate App</p>
            <p>For assistance or to report an issue, please submit a support request:</p>
            <a v-bind:href ="ticket" target="_blank" class="ticket-btn">Submit Support Request</a>
          </div>
          <div class="col-xxl-12">
            <p>Copyright &copy; {{currentYear}} | All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
    <!-- Access Code Pop up -->
    <b-modal
      id="accessCode-pop"
      centered
      :size="showRepConformation ? 'lg' : 'md'"
      v-model="accssShow"
    >
      <template #modal-header>
        <div class="popUp-header">
          <h4 v-if="!showRepConformation">Access Code</h4>
          <b-button variant="close-btn" @click="accssShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showRepConformation">
          <div class="form-group mb-3">
            <label>Rep Access Code</label>
            <input
              type="text"
              v-model="repAccessCode"
              @input="removeGroupErrorMessage()"
              :class="{
                'form-control is-invalid':
                  $v.repAccessCode.$error || repErrorFlag,
              }"
              class="custom-input"
              placeholder="Enter Rep Access Code Eg: ABCD1212"
            />
            <div
              class="form-group__message text-left ml-3 mt-2"
              v-if="!$v.repAccessCode.required && $v.repAccessCode.$error"
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="repErrorFlag === true">
              {{ this.repErrorMsg }}
            </div>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkRepEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showRepConformation">
          <div class="text-left mb-3">
            <div class="rep-confirm-header">
              <i class="fa fa-unlock-alt"></i
              ><strong class="ms-2"
                >Please confirm that you are accessing the site with the correct
                representative access code.</strong
              >
            </div>
            <div class="mt-4">
              <strong>{{ repInfo.first_name }} {{ repInfo.last_name }}</strong>
            </div>
            <div>{{ repInfo.agent_code }}</div>
            <div>{{ repInfo.email }}</div>
            <div class="mb-4">{{ repInfo.phone }}</div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToRepEnroll">Yes</button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetRepEnroll">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <!-- Employee Enrollment pop up -->
    <b-modal
      id="employeeEnrollment-pop"
      centered
      size="md"
      v-model="enrollmentShow"
    >
      <template #modal-header>
        <div class="popUp-header">
          <h4>Employee Enrollment</h4>
          <b-button variant="close-btn" @click="enrollmentShow = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div v-if="!showProceedConformation">
          <div class="form-group mb-3">
            <label>Group Enrollment Code</label>
            <input
              type="text"
              v-model="groupEnrollmentCode"
              @input="removeGroupErrorMessage()"
              :class="{
                'form-control is-invalid':
                  $v.groupEnrollmentCode.$error || groupErrorFlag,
              }"
              class="custom-input"
              placeholder="Enter Group Enrollment CodeEg: ABCD1212"
            />
            <div
              class="form-group__message text-left ml-3 mt-2"
              v-if="
                !$v.groupEnrollmentCode.required &&
                $v.groupEnrollmentCode.$error
              "
            >
              Field is required
            </div>
            <div class="error_message mt-2" v-if="groupErrorFlag === true">
              {{ this.groupErrorMsg }}
            </div>
          </div>
          <div class="form-group semiText">
            <p>
              If you don't know your group enrollment code please contact your
              company's human resource department.
            </p>
          </div>
          <div class="form-group">
            <button class="univ-btn" @click="checkGroupEnrollmentCode()">
              Continue
            </button>
          </div>
        </div>
        <div v-if="showProceedConformation">
          <div class="text-left mb-3">
            <div class="mt-2">
              The company that you will be enrolling under is:<strong
                style="color: #28a745"
              >
                {{ groupInfo.name }}</strong
              >
            </div>
            <div
              class="mt-2 group-not-employer-container"
              @click="backToEnrollmentCode"
            >
              <p>
                If this is NOT the your employer please click here, to re-enter
                your employer enrollment code.
              </p>
              <p>
                If you have questions please contact your Employer for correct
                code.
              </p>
            </div>
          </div>
          <b-row>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <div class="form-group">
                <button class="univ-btn" @click="routeToGroupEnroll">
                  Yes
                </button>
              </div>
            </b-col>
            <b-col cols="6" sm="6" md="6" lg="6" xl="6">
              <button class="univ-btn" @click="resetEnrollGroup">No</button>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-modal>

    <!-- Video Goenroll pop up -->
    <b-modal id="howToEnrollVideo" centered size="lg" v-model="show">
      <template #modal-header>
        <div class="popUp-header">
          <b-button variant="close-btn" @click="show = false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="videoPlay">
          <video
            controls
            autoplay
            width="100%"
            height="350"
            title="Goenroll123"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
            <source :src="videoLink" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import QuestionCall from "./QuestionCall.vue";
import { required } from "vuelidate/lib/validators";
import Helper from '../includes/Helper'
export default {
  name: "SiteFooter",
  components: {
    QuestionCall,
  },
  data: function () {
    return {
      phone: "Loading..",
      email: "Loading..",
      enrollmentShow: false,
      accssShow: false,
      ticket: "",
      footerIcon: "",
      iosImgLink:
        "https://benefit-store-assets.s3.us-east-2.amazonaws.com/ios_icon.png",
      androidImgLink:
        "https://benefit-store-assets.s3.us-east-2.amazonaws.com/google_icon.png",
      iosLink: "https://apps.apple.com/us/app/corenroll-member/id1539841827",
      androidLink:
        "https://play.google.com/store/apps/details?id=com.neura.corenroll_member&hl=en&gl=US",
      // ios: 'https://apps.apple.com/us/app/corenroll-reps/id1527267392',
      // android: 'https://play.google.com/store/apps/details?id=com.neura.corenroll',
      open_type: "",
      group_id: "",
      agent_id: "",
      googlePlay: true,
      siteUrl: process.env.VUE_APP_IS_GO_ENROLL,
      showProceedConformation: false,
      groupEnrollmentCode: null,
      groupRouteName: null,
      groupErrorFlag: false,
      groupErrorMsg:'',
      showRepConformation: false,
      repAccessCode: null,
      repRouteName: null,
      repErrorFlag: false,
      repErrorMsg:'',
      //routeToGroupRegistration: process.env.VUE_APP_GROUP_REGISTRATION,
      routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
      repInfo: {
        firstName: "",
        lastName: "",
      },
      groupInfo: {
        name: "",
      },
      menuLink: {
        routeToAdmin: process.env.VUE_APP_ADMIN,
        routeToMessageCenter: process.env.VUE_APP_MESSAGE_CENTER,
        routeToFileManagement: process.env.VUE_APP_FILE_MANAGEMENT,
        routeToRepDashboard: process.env.VUE_APP_REP_DASHBOARD,
        routeToMemberDashboard: process.env.VUE_APP_MEMBER_DASHBOARD,
        routeToGroupDashboard: process.env.VUE_APP_GROUP_DASHBOARD,
        routeToTicketManagement: process.env.VUE_APP_TICKET_MANAGEMENT,
        routeToSos: process.env.VUE_APP_SOS,
        routeToRepRegistration: `${process.env.VUE_APP_REP_REGISTRATION}${
          localStorage.getItem("agent_code")
            ? `?repCode=${localStorage.getItem("agent_code")}`
            : ""
        }`,
        routeToMemberRegistration: process.env.VUE_APP_MEMEBER_REGISTRATION,
        routeToGroupRegistration: `${process.env.VUE_APP_GROUP_REGISTRATION}${
          localStorage.getItem("agent_code")
            ? `?repCode=${localStorage.getItem("agent_code")}`
            : ""
        }`,
      },
      show: false,
      videoLink: "",
      modelId: "",
      turnkeySolutionsInfo: {
        phoneNumber: process.env.VUE_APP_TURKEY_SOLUTION_PHONE_NUMBER,
        email: process.env.VUE_APP_TURKEY_SOLUTION_EMAIL
      }
    };
  },
  validations: {
    repAccessCode: {
      required,
    },
    groupEnrollmentCode: {
      required,
    },
  },
  computed: {
    currentYear () {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      return currentYear
    },
    continueEnrollmentFlag () {
      return this.$store.getters.getContinueEnrollmentFlagState
    },
    isEmployeeEnrollment () {
      return  this.$store.getters.isEmployeeEnrollment
    },
    getAgentInfo () {
      return this.$store.getters.getAgentInfo
    }
  },
  mounted() {
    let app = this;
    app.group_id = window.localStorage.getItem("group_id");
    app.agent_id = window.localStorage.getItem("agent_id");
    app.open_type = window.localStorage.getItem("open_type");
    app.eprocess = window.localStorage.getItem("eprocess");
    if (app.open_type === "group" || app.eprocess === "group") {
      app.getGroupHeaderInfo();
    } else {
      //open landing pages
      app.getAgentHeaderInfo();
    }
    if (
      app.$device.ios ||
      app.$device.iphone ||
      app.$device.iphoneX ||
      app.$device.iPhoneXR ||
      app.$device.iPhoneXSMax ||
      app.$device.ipod ||
      app.$device.ipad ||
      navigator.platform.indexOf("Mac") > -1
    ) {
      app.googlePlay = false;
    }
    app.$root.$on("infoLanding", (info) => {
      app.phone = info.phone;
      app.email = info.email;
      app.getTicketLink();
    });
    app.fetchCatchInfo();
    if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
      app.getTicketLink();
    }
  },
  methods: {
    getTicketLink: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-ticket-link")
        .then(function (response) {
          app.ticket = response.data.data["ticket"];
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getAgentHeaderInfo: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-agent-landing-page-info", {
          params: {
            agent_id: app.agent_id,
          },
        })
        .then(function (response) {
          window.localStorage.setItem(
            "agent_code",
            response.data.data.agent_code
          );
          app.menuLink.routeToRepRegistration = `${
            process.env.VUE_APP_REP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.menuLink.routeToGroupRegistration = `${
            process.env.VUE_APP_GROUP_REGISTRATION
          }${
            localStorage.getItem("agent_code")
              ? `?repCode=${localStorage.getItem("agent_code")}`
              : ""
          }`;
          app.phone = response.data.data.display_phone;
          app.email = response.data.data.display_email;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getGroupHeaderInfo: function () {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/get-group-landing-page-info", {
          params: {
            group_id: app.group_id,
          },
        })
        .then(function (response) {
          window.localStorage.setItem(
            "agent_code",
            response.data.data.agent_code
          );
          app.menuLink.routeToGroupRegistration = `${
            process.env.VUE_APP_GROUP_REGISTRATION
          }${app.isEmployeeEnrollment ? `?repCode=${localStorage.getItem("agent_code")}` : `?repCode=${app.getAgentInfo.agent_code}`}`;
          app.menuLink.routeToRepRegistration = `${
            process.env.VUE_APP_REP_REGISTRATION
          }${app.isEmployeeEnrollment ? `?repCode=${localStorage.getItem("agent_code")}` : `?repCode=${app.getAgentInfo.agent_code}`}`;
          app.phone = response.data.data.display_phone;
          app.email = response.data.data.display_email;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(
        unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN))
      );
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`;
      caches
        .open("fetch_color_cache")
        .then((cache) => {
          cache.match(url).then((response) => {
            response.json().then((data) => {
              let getCatchColor = [];
              let newObj = {};
              getCatchColor = data;
              getCatchColor.map((item) => {
                newObj[item.key] = item.value;
              });
              this.footerIcon = newObj["VUE_APP_FOOTER_LOGO"];
              this.iosImgLink = newObj["VUE_APP_IOS_ICON"];
              this.androidImgLink = newObj["VUE_APP_ANDROID_ICON"];
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkGroupEnrollmentCode() {
      let app = this;
      app.$v.groupEnrollmentCode.$touch();
      if (app.$v.groupEnrollmentCode.$invalid) {
        return false;
      } else {
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-group", {
            params: {
              group_code: app.groupEnrollmentCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.groupInfo.name = response.data.data.name;
              app.showProceedConformation = true;
            }
            if (response.status == 204) {
              app.groupErrorFlag = true;
              app.showProceedConformation = false;
            }
          })
          .catch(function (error) {
            app.groupErrorFlag=true
            app.groupErrorMsg=error.response.data.message;
            app.showProceedConformation = false;
          });
      }
    },
    resetEnrollGroup() {
      let app = this;
      app.showProceedConformation = false;
      app.enrollmentShow = false;
      app.groupEnrollmentCode = null;
      app.groupErrorFlag = false;
      app.$v.$reset();
    },
    routeToGroupEnroll() {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/validate-group", {
          params: {
            group_code: app.groupEnrollmentCode,
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            app.$store.dispatch("isEmployeeEnrollment", true);
            let responseData = response.data.data;
            app.groupRouteName = responseData.landing_url;
            app.enrollmentShow = false;
            let enrollType = "Group";
            let redirectionUrl = 'landingPage'
            if (
              responseData.landing_url == null ||
              responseData.landing_url == ""
            ) {
              app.$router.push("/landing-config-error/" + enrollType + '/' + redirectionUrl);
            } else {
              app.$router.push({name: 'main'})
              app.$store.dispatch("landingInfo", null);
              app.$store.dispatch("repInfo", null);
              app.$store.dispatch("groupInfo", null);
              app.$store.dispatch('landingUrl', encodeURIComponent(app.groupRouteName))
              app.$router.go();
            }
          }
          if (response.status == 204) {
            app.groupErrorFlag = true;
            app.showProceedConformation = false;
          }
        })
        .catch(function (error) {
          app.groupErrorFlag = true;
          app.groupErrorMsg=error.response.data.message
          app.showProceedConformation = false;
        });
    },
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
      app.repErrorFlag = false;
    },
    checkRepEnrollmentCode() {
      let app = this;
      app.$v.repAccessCode.$touch();
      if (app.$v.repAccessCode.$invalid) {
        return false;
      } else {
        let app = this;
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-agent", {
            params: {
              agent_code: app.repAccessCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.showRepConformation = true;
              app.repInfo = { ...response.data.data };
              let formatted_phone = `(${app.repInfo.phone.slice(
                0,
                3
              )}) ${app.repInfo.phone.slice(3, 6)}-${app.repInfo.phone.slice(
                6,
                10
              )}`;
              app.repInfo.phone = formatted_phone;
            }
            if (response.status == 204) {
              app.repErrorFlag = true;
              app.showRepConformation = false;
            }
          })
          .catch(function (error) {
            app.repErrorFlag = true;
            app.repErrorMsg=error.response.data.message;
            app.showRepConformation = false;
          });
      }
    },
    routeToRepEnroll() {
      let app = this;
      axios
        .get(process.env.VUE_APP_API_BASE + "/validate-agent", {
          params: {
            agent_code: app.repAccessCode,
          },
        })
        .then(function (response) {
          if (response.data.status == "success") {
            let responseData = response.data.data;
            app.repRouteName = responseData.landing_url;
            app.enrollmentShow = false;
            let enrollType = "Agent";
            let redirectionUrl = 'landingPage'
            if (
              responseData.landing_url == null ||
              responseData.landing_url == ""
            ) {
              app.$router.push("/landing-config-error/" + enrollType + '/' + redirectionUrl);
            } else {
              app.$router.push({name: 'main'})
              app.$store.dispatch("landingInfo", null);
              app.$store.dispatch("repInfo", null);
              app.$store.dispatch("groupInfo", null);
              app.$store.dispatch('landingUrl', encodeURIComponent(app.repRouteName))
              app.$router.go();
            }
          }
          if (response.status == 204) {
            app.repErrorFlag = true;
            app.showRepConformation = false;
          }
        })
        .catch(function (error) {
          app.repErrorFlag = true;
          app.repErrorMsg=error.response.data.message;
          app.showRepConformation = false;
        });
    },
    resetRepEnroll() {
      let app = this;
      app.showRepConformation = false;
      app.accssShow = false;
      app.repAccessCode = null;
      app.repErrorFlag = false;
      app.$v.$reset();
    },
    routeToNotMySITE() {
      Helper.redirectToAcessPage(this)
    },
    getVideoLink(link) {
      let app = this;
      if (link === "howToEnrollVideo") {
        app.videoLink =
          "https://benefit-store-assets.s3.us-east-2.amazonaws.com/Member_How_to_GoEnroll123com_3.mp4";
      } else {
        app.videoLink =
          "https://benefit-store-assets.s3.us-east-2.amazonaws.com/Employer_Group_Goenroll123_V3.mp4";
      }
    },
    backToEnrollmentCode() {
      let app = this;
      app.showProceedConformation = false;
      app.groupEnrollmentCode = null;
      app.$v.$reset();
    },
  },
  listener: {
    FromDirect() {
      let app = this;
      app.group_id = window.localStorage.getItem("group_id");
      app.agent_id = window.localStorage.getItem("agent_id");
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    },
    externalsite() {
      let app = this;
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    },
    renderSiteRouteInfo () {
      let app = this;
      app.group_id = window.localStorage.getItem("group_id");
      app.agent_id = window.localStorage.getItem("agent_id");
      app.open_type = window.localStorage.getItem('open_type');
      app.eprocess = window.localStorage.getItem('eprocess');
      if (app.open_type === "group" || app.eprocess === "group") {
        app.getGroupHeaderInfo();
      } else {
        app.getAgentHeaderInfo();
      }
    }
  },
};
</script>
<style lang="scss">
#accessCode-pop {
  .modal-dialog {
    max-width: 650px !important;
  }
  #modal-header {
    padding-top: 0px !important;
  }
}

.group-not-employer-container {
  p {
    color: #dc3545;
    cursor: pointer;
    margin-bottom: 0;
    font-size: 14px;
  }
  &:hover {
    text-decoration: underline;
    color: #dc3545;
  }
}
.copyright {
  color: #777;
}
.access-code-wrap {
  display: grid;
  margin: 0px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  .pop-title {
    font-size: 20px;
    text-align: center;
    color: #12404d;
    font-weight: bold;
  }
  img {
    margin: auto;
    height: 100px;
    margin-bottom: 1rem;
  }
  p {
    width: auto;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-top: 0.5rem;
  }
  input {
    padding: 0.5rem 1rem;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    outline: none;
    margin-bottom: 1rem;
  }
}
</style>
