<template>
  <div class="container">
    <!-- <h4 class="mb-0 mt-5">Let make easy way through it.</h4> -->
    <div class="member-wrap">
      <div class="landing-wrap">
        <div class="form-wrap">
          <h1 style="color: #00ccff; font-weight: 900">GET A QUOTE</h1>
          <h6 class="label-form mb-3">
            Complete the following questions to view available plans and compare their costs.
          </h6>
          <div class="row my-4">
            <div class="col-md-6 input-container">
              <label class="label-form">Enter ZIP Code</label><br />
              <input
                  class="input-field"
                  placeholder="Enter your ZIP Code"
                  v-model="zip"
                  id="zip"
                  value=""
                  @input="$v.zip.$touch(); removeError();"
                  :class="{'form-control is-invalid':$v.zip.$error || formErrors['zip_message']}"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                  maxlength="5"
              />
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.zip.required && $v.zip.$error">
                Field is required
              </div>
              <div class="form-group__message  ml-3 mt-2" v-if="$v.zip.required && $v.zip.$error">
                Enter 5 digit zip code.
              </div>
              <div class="error_message" v-if="formErrorFlag['zip'] == true">{{formErrors['zip_message']}}</div>
            </div>

            <div class="col-md-6 input-container">
              <label class="label-form">What is your Date of Birth?</label
              ><br />
              <v-date-picker
                  v-model="dob"
                  :value="null"
                  :popover="{ visibility: 'click' }"
                  :max-date="new Date()"
                  @input="$v.dob.$touch(); removeError();"
                  mode="date">
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                      class="custom-input"
                      placeholder="mm/dd/yyyy"
                      size=10 maxlength=10
                      :class="{'invalid':$v.dob.$error || formErrors['dob_message']}"
                      :value="inputValue.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                      v-on="inputEvents"
                  />
                </template>
              </v-date-picker>
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.dob.required && $v.dob.$error">
                Field is required
              </div>
              <div class="form-group__message  ml-3 mt-2" v-if="$v.dob.required && $v.dob.$error">
                You must be 18 years to proceed
              </div>
              <div class="error_message" v-if="formErrorFlag['dob'] == true">{{formErrors['dob_message']}}</div>
            </div>
            <div class="col-md-6 col-lg-5 col-xl-6">
              <div class="input-container mt-2 mb-2 mt-4">
                <label for="">Gender</label>
                <div>
                  <b-form-select v-model="gender"
                                 class="form-control"
                                 @input="$v.gender.$touch(); removeError();"
                                 :class="{'form-control is-invalid':$v.gender.$error || formErrors['gender_message']}"
                                 id="gender"
                                 :options="genderOptions">
                  </b-form-select>
                </div>
                <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.gender.required && $v.gender.$error">
                  Field is required
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex">
                <form action="">
                  <div class="row">
                    <div class="">
                      <h6 class="mb-3 mt-4">Add family Member</h6>
                      <div class="d-flex">
                        <div class="">
                          <div class="add-dep-option" @click="toggleDiv" tabindex="0">
                            Add Spouse
                          </div>
                        </div>
                        <div class="">
                          <div
                              class="add-dep-option"
                              @click="toggleDivChild"
                              tabindex="1"
                          >
                            Add Dependents
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-row class="mt-1">
                      <b-col md="6" lg="6" xl="6" :class="{'hide-dependent-container': !showDiv}">
                        <div v-if="showDiv" class="pt-2">
                          <h6 class="mb-2 pb-2">
                            Please provide <b>Date of Birth of Partner/Spouse</b>
                          </h6>
                          <div class="mb-3">
                            <v-date-picker
                                v-model="spouseDOB"
                                :value="null"
                                :popover="{ visibility: 'click' }"
                                :max-date="new Date()"
                                @input="removeError(); convertIntoDateFormat();"
                                mode="date">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input
                                    class="custom-input"
                                    placeholder="mm/dd/yyyy"
                                    size="10"
                                    maxlength="10"
                                    :value="
                            inputValue
                              .replace(/^(\d\d)(\d)$/g, '$1/$2')
                              .replace(/^(\d\d\/\d\d)(\d+)$/g, '$1/$2')
                              .replace(/[^\d\/]/g, '')
                          "
                                    v-on="inputEvents"
                                />
                              </template>
                            </v-date-picker>
                            <button
                                class="remove-btn"
                                type="button"
                                @click="removeButton"
                            >
                              Remove <i class="fas fa-trash ms-2"></i>
                            </button>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="6" lg="6" xl="6" :class="{'hide-dependent-container': !showDivChild}">
                        <div v-if="showDivChild" class="pt-2">
                          <div>
                            <h6 class="child-text">Select<b> number of dependents</b></h6>
                            <input
                                type="number"
                                v-model="numberValue"
                                class="input-field w-50 me-1"
                            />
                          </div>
                          <div class="child-btn-container">
                            <button class="btn-num ms-0" type="button" @click="decrement">
                              <i class="fas fa-minus"></i>
                            </button>
                            <button class="btn-num" type="button" @click="increment">
                              <i class="fas fa-plus"></i>
                            </button>

                            <button
                                class="btn-clear-num ms-0 btn-clear"
                                @click="clearButton"
                                type="button"
                            >
                              Clear
                            </button>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </form>
              </div>
            </div>
            <div class="">
              <h6 class="label-form mb-3 mt-4">
                What type of plan(s) are you interested in? Please choose one.
              </h6>
              <div class="row" v-if="planCategories && planCategories.data">
                <div
                    class="col-xxl-3 col-md-3 col-sm-6 mb-4"
                    v-for="(item, index) in planCategories.data"
                    :key="index"
                >
                  <div
                      class="plan-option"
                      tabindex="0"
                      @click="selectCategory(item)"
                  >
                    <img
                        v-if="item && planCategories.icon[item]"
                        :src="planCategories.icon[item]"
                    />
                    <a v-if="item == 'term_life'">Term Life</a>
                    <a v-else style="text-transform: capitalize">{{ item }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p v-if="categoryError" class="error_message category-error">
            {{ categoryError }}
          </p>
          <div class="submit-btn">
            <button @click="submitForm">Submit</button>
          </div>
        </div>
        <div class="right-side-form">
          <h3 class="mb-3">Let's Begin the journey</h3>
          <img src="../../assets/images/close-up.png" class="cover-img" />
        </div>
      </div>
    </div>
    <leads-storage />
    <!-- new plans -->
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import { checkValidZip, isOver18 } from "@/utils/validators";
import LeadsStorage from "../lead-storage/LeadsStorage.vue";
export default {
  name: "LandingPage",
  components: {
    LeadsStorage,
  },
  data() {
    return {
      zip: null,
      dob: null,
      agent_id: null,
      category: null,
      categoryError: null,
      coverage: "LOW_COST",
      eprocess: null,
      group_id:  null,
      platform_group_id: null,
      tier: "",
      planCategories: null,
      formErrors: [],
      formErrorFlag: [],
      dobError: null,
      zipError: null,
      tempId: null,
      showDiv: false, // Initialize to hide the div
      showDivChild: false,
      numberValue: 0,
      spouseDOB: "",
      selectedNewPlan: [],
      selectedNewElevatePlan: [],
      error: "",
      specialError: "",
      gender: '',
      genderOptions: [
        { value: '', text: 'Choose Gender', disabled: true},
        { value: '0', text: 'Male' },
        { value: '1', text: 'Female'}
      ]
    };
  },
  computed: {
    locationZipCode () {
      return this.$store.getters.getZipCode
    }
  },
  validations: {
    zip: {
      required,
      checkValidZip,
    },
    dob: {
      required,
      isOver18,
    },
    gender: {
      required
    }
  },
  mounted() {
    let app = this
    app.agent_id = window.localStorage.getItem("agent_id"),
        app.eprocess = window.localStorage.getItem("eprocess"),
        app.group_id = window.localStorage.getItem("portal_id"),
        app.platform_group_id = window.localStorage.getItem("group_id"),
        app.open_type = window.localStorage.getItem("open_type"),
        app.tempId = window.localStorage.getItem('tempId');
    app.callCommonMenu();
    app.getFilters();
  },
  methods: {
    removeError() {
      this.formErrors = [];
    },
    increment() {
      this.numberValue++;
    },
    decrement() {
      this.numberValue <= 0 ? 0 : this.numberValue--;
    },
    toggleDiv() {
      // this.showDivChild = false;
      // this.showDiv = !this.showDiv; // Toggle the showDiv value on click
      // this.showDivChild = !this.showDivChild;
      this.showDiv = true
    },
    toggleDivChild() {
      // this.showDiv = false;
      this.showDivChild = true // Toggle the showDiv value on click
    },
    removeButton () {
      this.spouseDOB = "";
      this.showDiv = false
    },
    clearButton () {
      this.numberValue = 0;
      this.showDivChild = false
    },
    updateDependent(enrollmentId) {
      let app = this;
      let params = {
        enrollment_id: enrollmentId ? enrollmentId: '',
        no_of_children: app.numberValue,
        dep_dob: app.spouseDOB,
      };
      axios.post(process.env.VUE_APP_API_BASE + "/store-temp-dependent-info", params)
          .then(function (response) {
            console.log(response);
            app.error = "";
          })
          .catch(function (error) {
            app.handleError(error, app);
          });
    },
    callCommonMenu() {
      let params = {
        agent_id: this.agent_id,
        group_id: this.platform_group_id,
        portal_id: this.group_id,
        eprocess: this.eprocess,
        open_type: this.open_type
      };
      console.log('landing page info', params);
      axios
          .get(process.env.VUE_APP_API_BASE + "/get-common-menu", {
            params: {
              agent_id: this.agent_id,
              group_id: this.platform_group_id,
              portal_id: this.group_id,
              eprocess: this.eprocess,
              open_type: this.open_type
            },
          })
          .then((response) => {
            this.planCategories = response.data;
            window.localStorage.setItem(
                "planCategories",
                JSON.stringify(this.planCategories.data)
            );
          })
          .catch((error) => {
            console.log(error);
          });
    },
    selectCategory(value) {
      this.category = value;
      this.categoryError = "";
      window.localStorage.setItem("new_landing_category", value);
    },
    checkTier() {
      let app = this;
      let childNumber = app.numberValue
      if (app.spouseDOB && childNumber > 0) {
        app.tier = 'IF'
      } else if (app.spouseDOB) {
        app.tier = 'IS'
      } else if (childNumber > 0) {
        app.tier = 'IC'
      } else {
        app.tier = 'IO'
      }
      window.localStorage.setItem("selectedTier", this.tier? this.tier : 'IO');
    },
    convertIntoDateFormat () {
      let app = this
      let date = this.spouseDOB
      let dd = date.getDate()
      let mm = date.getMonth() + 1
      let yyyy = date.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      app.spouseDOB = mm + '/' + dd + '/' + yyyy;
    },
    submitForm() {
      this.$v.$touch();
      if (this.$v.$invalid || !this.category) {
        this.categoryError = "Please select a category.";
        console.log('***** Inside Save Data Invalid*****')
        return false
      } else {
        const dateString = this.dob;
        const date = new Date(dateString);
        const dd = String(date.getUTCDate()).padStart(2, "0");
        const mm = String(date.getUTCMonth() + 1).padStart(2, "0");
        const yyyy = date.getUTCFullYear();
        const formattedDate = mm + "/" + dd + "/" + yyyy;

        this.checkTier();
        if (this.tempId == null) {
          axios.post(process.env.VUE_APP_API_BASE + "/add-filter-for-new-user", {
            agent_id: this.agent_id,
            categories: this.category,
            coverage: this.coverage,
            dob: formattedDate,
            eprocess: this.eprocess,
            gender: this.gender,
            group_id: this.platform_group_id,
            platform_group_id: this.group_id,
            tier: this.tier? this.tier : 'IO',
            type: window.localStorage.getItem("enroll_type"),
            open_type: this.open_type,
            zip: this.zip,
          }).then((response) => {
            window.localStorage.setItem(
                "tempId",
                response.data.data.temp_enrollment_id
            );
            const filterValues = {
              zip: response.data.data.result.zip,
              dob: response.data.data.result.dob,
            };

            const filterString = JSON.stringify(filterValues);

            window.localStorage.setItem("added_filter", filterString);
            this.updateDependent(response.data.data.temp_enrollment_id)
            this.$root.$emit('cartData', response.data.data.temp_enrollment_id)
            this.$router.push(`/new-plans`);

            this.$router.push(`/new-plans`);
          })
              .catch((error) => {
                let errorMessage = [];
                let errorFlag = [];
                if (error.response.data.status === "error") {
                  error.response.data.data.forEach((item) => {
                    errorFlag[item.target_element] = true;
                    errorMessage[item.target_element + "_message"] =
                        item.error_message;
                    if (item.target_element === "dob") {
                      this.dobError = item.error_message;
                    } else if (item.target_element === "zip") {
                      this.zipError = item.error_message;
                    }
                  });
                }
                this.formErrorFlag = errorFlag;
                this.formErrors = errorMessage;
              });
        } else {
          axios.put(process.env.VUE_APP_API_BASE + '/change-filter-info/' + this.tempId, {
            agent_id: this.agent_id,
            categories: this.category,
            coverage: this.coverage,
            dob: formattedDate,
            eprocess: this.eprocess,
            gender: this.gender,
            group_id: this.platform_group_id,
            platform_group_id: this.group_id,
            tier: this.tier,
            type: window.localStorage.getItem("enroll_type"),
            open_type: this.open_type,
            zip: this.zip
          }).then((response) => {
            if (response.status == 200) {
              this.updateDependent(this.tempId)
              this.$root.$emit('cartData', this.tempId)
              this.$router.push(`/new-plans`);
            }
          }).catch((error) =>  {
            console.log(error)
            let errorMessage = [];
            let errorFlag = [];
            if (error.response.data.status === "error") {
              error.response.data.data.forEach((item) => {
                errorFlag[item.target_element] = true;
                errorMessage[item.target_element + "_message"] =
                    item.error_message;
                if (item.target_element === "dob") {
                  this.dobError = item.error_message;
                } else if (item.target_element === "zip") {
                  this.zipError = item.error_message;
                }
              });
            }
            this.formErrorFlag = errorFlag;
            this.formErrors = errorMessage;
          });
        }
      }
    },
    getFilters: function () {
      let app = this;
      if(app.tempId != null){
        axios.get(process.env.VUE_APP_PLAN_COVERAGE_API + '/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'filter_info'
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.dob = response.data.data.result.dob;
                app.zip = response.data.data.result.zip ? response.data.data.result.zip : app.locationZipCode;
                app.gender = response.data.data.result.gender;
                app.checkDependentForm(response.data.data.result);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
      }
    },
    checkDependentForm (filterInfo) {
      let app = this
      if (filterInfo?.spouse_dop) {
        app.spouseDOB = filterInfo.spouse_dop
        app.toggleDiv();
      }

      if (filterInfo?.child_num) {
        app.numberValue = filterInfo.child_num
        app.toggleDivChild();
      }
      // if (filterInfo?.)
    },
  },
  listener: {
    'getGroupDetail'() {
      if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
        let app = this
        app.group_id = window.localStorage.getItem('portal_id');
        app.platform_group_id = window.localStorage.getItem('group_id');
        app.callCommonMenu();
      }
    },
  }
};
</script>
<style scoped lang="scss">
.category-error {
  position: relative;
  top: -23px;
}

.child-text {
  margin-bottom: 35px;
  padding-left: 2px;
}

.child-btn-container {
  padding-top: 14px;
}

.hide-dependent-container {
  display: none;
}
</style>
